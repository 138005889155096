import React from "react";
import { useTranslation } from "react-i18next";
import { LifeEventType, MemoryType, LifeEvent } from "../AppType";
import { useContext, useEffect, useState } from "react";
import { fetchRecords, debug } from "../../utils/AppUtil";
import { LifeEventDetailed } from "./LifeEventDetailed";
import { AppContext } from "../../AppContext";
import { ModalCreateEvent } from "../Modals/ModalCreateEvent";
import { ModalCreateFamilyStory } from "../Modals/ModalCreateFamilyStory";
import { MemorialContext } from "../../MemorialContext";

export const LifeStory: React.FC = () => {

    // Internationalization
    const { t } = useTranslation();
    
    // Authentication data inside AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data inside MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [memoriesState, setMemoriesState] = useState<React.ReactElement[]>();
    const [lifeEventState, setLifeEventState] = useState<React.ReactElement[]>();
    const [indicatorButtons, setIndicatorButtons] = useState<string[]>();

    const [selectedEventTitle, setSelectedEventTitle] = useState("");
    const [selectedEventDate, setSelectedEventDate] = useState("");
    const [selectedEventMessage, setSelectedEventMessage] = useState("");
    const [isChildClick, setIsChildClick] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [viewerState, setViewerState] = useState<boolean>(false);

    useEffect(() => {

        fetchRecords(commonData.token, `memorial/${memorialData.memorialId}/life_event_decade`, processTimelineData)
        .catch((error) => {
            setIsLoading(false);
            debug('fetchRecords error: ' + error, 'life_event');
        });

        fetchRecords(commonData.token, `memorial/${memorialData.memorialId}/memory`, processMemoriesData)
        .catch((error) => {
            setIsLoading(false);
            debug('fetchRecords error: ' + error, 'memory');
        });

        if (memorialData.role === "VIEWER" && memorialData.familyStory === null) {
            setViewerState(true)
        } else {
            setViewerState(false)
        }

    }, [memorialData]);

    // Simulate click UE to combat mobile touch swipe bug
    useEffect(() => {
        if (indicatorButtons) {
            for (let i = 0; i < indicatorButtons.length; i++) {
                document.getElementById(indicatorButtons[i])?.click();
            }
        }

    }, [indicatorButtons]);

    const truncate = (text: string) => {
        return text.length > 103 ? text.substring(0, 100) + "..." : text;
    }

    const handleChildButtonClick = (e: React.MouseEvent<HTMLImageElement>) => {
        e.stopPropagation();
        setIsChildClick(true);
        console.log('Child button clicked');
    }

    const processMemoriesData = (responseJson: MemoryType[]) => {
        setMemoriesState(buildMemoriesListJSX(responseJson));
    }

    const buildMemoriesListJSX = (data: MemoryType[]): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 100;

        data.forEach((memory: MemoryType) => {
            result.push(

                // <div key={key} className="timeline-btn mb-5">
                //     <img src="/images/fl-plus-lg.svg" className="mb-3" />
                //     <p className="timeline-btn-title">{ t("Timeline.addMemory", {ns: "components"}) }</p>
                // </div>

                <div key={key} className="memory-card d-flex flex-column">
                    <div className="memory-card-image">
                        <img src="/images/Rectangle17.png" className="memory-image" style={{mixBlendMode: "luminosity"}}/>
                        <img src="/images/fl-play-button.svg" className="timeline-image-play" />
                    </div>
                    <div className="memories-note d-flex flex-column justify-content-between p-3">
                        <p className="mb-2">{memory.title}</p>
                        <p>From {memory.creator.firstname} {memory.creator.lastname}</p>
                    </div>
                </div>
            );
            key++;
        });

        return result;
    }

    const processTimelineData = (responseJson: LifeEventType[]) => {
        setIsLoading(true);
        setLifeEventState(buildLifeEventListJSX(responseJson));
        setIsLoading(false);
    }

    const timelineYearBadges = (lifeEvent: LifeEvent[]) => {
        let result = new Map<number, boolean>();

        for (let i = 0; i < lifeEvent.length; i++) {
            result.set(lifeEvent[i].eventYear, true);
        }

        return result;
    }

    const timelineImagesArray = [
        <img src="/images/carouselPlaceholders/carousel-img-1.png" className="timeline-image" />,
        <img src="/images/carouselPlaceholders/carousel-img-2.png" className="timeline-image" />,
        <img src="/images/carouselPlaceholders/carousel-img-3.png" className="timeline-image" />,
        <img src="/images/carouselPlaceholders/carousel-img-4.png" className="timeline-image" />,
    ];

    const buildLifeEventListJSX = (data: LifeEventType[]): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 1000;
        let indicatorButtonsArray: string[] = [];

        data.forEach((lifeEventType: LifeEventType) => {

            if ('DEFAULT_BIRTH' === lifeEventType.lifeEvents[0].type) {
                result.push(
                    <div key={key} className="timeline-item-initial ps-5">
                        <div className="timeline-date-breaker">{lifeEventType.decadeDisplay}</div>
                    </div>
                );
            } else {
                result.push(
                    <div key={key} className="timeline-item ps-5">
                        <div className="timeline-date-breaker">{lifeEventType.decadeDisplay}</div>
                    </div>
                );
            }

            let badges = timelineYearBadges(lifeEventType.lifeEvents);

            for (let i = 0; i < lifeEventType.lifeEvents.length; i++) {

                const currentEventId = lifeEventType.lifeEvents[i].lifeEventId;
                indicatorButtonsArray.push(currentEventId + '-ind');

                const messageText = truncate(lifeEventType.lifeEvents[i].message);
                let display = badges.get(lifeEventType.lifeEvents[i].eventYear);

                // --- Image carousel items --- //

                // Set initial image for active class
                let timelineImages: any[] = [
                    <div className="carousel-item active">
                        {timelineImagesArray[0]}
                    </div>
                ];

                // Add remaining images
                for (let y = 1; y < timelineImagesArray.length; y++) {
                    timelineImages.push(
                        <div className="carousel-item">
                            {timelineImagesArray[y]}
                        </div>
                    );
                }

                // Set initial indicator for active class and click element with UE - See top
                let timelineImageIndicators: any[] = [
                    <button id={currentEventId + '-ind'} type="button" data-bs-target={"#" + currentEventId} data-bs-slide-to="0" className="indicator-initial active" aria-current="true" aria-label="" data-bs-interval="false" data-bs-touch="true" data-bs-ride="carousel"></button>
                ];
                
                // Add remaining indicators
                for (let y = 1; y < timelineImagesArray.length; y++) {
                    timelineImageIndicators.push(
                        <button type="button" data-bs-target={"#" + currentEventId} data-bs-slide-to={y} aria-label="" data-bs-interval="false" data-bs-touch="true" data-bs-ride="carousel"></button>
                    );
                }
  
                if ('DEFAULT_BIRTH' === lifeEventType.lifeEvents[i].type) {
                    result.push(
                        <div key={lifeEventType.lifeEvents[i].lifeEventId}>      
                            <div className="timeline-item ps-5 py-4">

                                {display &&
                                    <div className="timeline-year-badge">
                                        <p>{lifeEventType.lifeEvents[i].eventYear}</p>
                                    </div>
                                }
    
                                <div className="timeline-card d-flex flex-column">
                                    {/* <img src="/images/Rectangle27.png" className="timeline-image" /> */}
                                    <div className="p-3" style={{position: 'relative'}}>
                                        <div className="timeline-note">
                                            <p>{lifeEventType.lifeEvents[i].title}</p>
                                            <p className="timeline-note-date mb-2">{lifeEventType.lifeEvents[i].eventDateDisplay}</p>
                                            <p>{messageText}</p>
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    );
                } else if ('USER_CREATED' === lifeEventType.lifeEvents[i].type){
                    result.push(
                        <div key={lifeEventType.lifeEvents[i].lifeEventId}>
                        
                            <div className="timeline-item ps-5 py-4">

                                {display &&
                                    <div className="timeline-year-badge">
                                        <p>{lifeEventType.lifeEvents[i].eventYear}</p>
                                    </div>
                                }

                                <div className="timeline-card d-flex flex-column" >

                                    <div className="d-flex">

                                        <div id={currentEventId} className="carousel slide w-100">
                                            <div className="carousel-indicators">
                                                {timelineImageIndicators}
                                            </div>

                                            <div className="carousel-inner">
                                                {timelineImages}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div onClick={() => { if (!isChildClick) {
                                        setSelectedEventTitle(lifeEventType.lifeEvents[i].title);
                                        setSelectedEventDate(lifeEventType.lifeEvents[i].eventDateDisplay);
                                        setSelectedEventMessage(lifeEventType.lifeEvents[i].message);}}}
                                        data-bs-toggle={!isChildClick ? 'modal' : undefined} data-bs-target={!isChildClick ? '#lifeEventDetailed' : undefined} style={{cursor: "pointer"}} >

                                        <div className="timeline-location-pill d-flex gap-2 px-2 py-1 mx-3 mt-3">
                                            <img src="/images/fl-map-pin.svg" />
                                            <p>Cape Town, SA</p>
                                        </div>

                                        <div className="p-3" style={{position: 'relative'}}>
                                            <div className="timeline-note">
                                                <p>{lifeEventType.lifeEvents[i].title}</p>
                                                <p className="timeline-note-date mb-2">{lifeEventType.lifeEvents[i].eventDateDisplay}</p>
                                                <p>{messageText}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    );
                } else if ('DEFAULT_DEATH' === lifeEventType.lifeEvents[i].type) {
                    result.push(
                        <div key={lifeEventType.lifeEvents[i].lifeEventId}>
    
                            <div className="timeline-item ps-5 py-4">

                                {display &&
                                    <div className="timeline-year-badge">
                                        <p>{lifeEventType.lifeEvents[i].eventYear}</p>
                                    </div>
                                }

                                {/* style={{  }} */}
                                <div className="timeline-tombstone-top card-container-top d-flex justify-content-center" style={{backgroundImage: "url('/images/tombstone-placeholder.png')"}}>
                                    <div className="tombstone-text timeline">
                                        <p>{memorialData.firstName}<br />{memorialData.lastName}</p>
                                        <p>{memorialData.birthDateDisplay}<br />{memorialData.deathDateDisplay}</p>
                                    </div>
                                </div>
                                <div className="timeline-tombstone-bottom card-container-bottom">
                                    <div className="timeline-card-tombstone" style={{position: 'relative'}}>
                                        <div className="timeline-note">
                                            <p>{ t("Timeline.timelineDeath", {name: `${memorialData.firstName}`, ns: "components"}) }</p>
                                            <p className="timeline-note-date">{lifeEventType.lifeEvents[i].eventDateDisplay}</p>
                                            {/* <p>{ t("Timeline.timelineDeath", {name: `${memorialData.firstName}`, ns: "components"}) }</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }

                if (display) {
                    badges.set(lifeEventType.lifeEvents[i].eventYear, false);
                }
            }
            key++;
        });

        setIndicatorButtons(indicatorButtonsArray);
        return result;
    }

    if (isLoading) {
        return(
            <div className="d-flex justify-content-center align-items-center" style={{height: "100dvh"}}>
                <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={`p-${viewerState ? "0" : "3"}`}>
                {memorialData.role !== "VIEWER" &&
                    <ModalCreateEvent />
                }

                {(memorialData.role === "VIEWER" || memorialData.role === "EDITOR") && memorialData.familyStory === null ?
                    <></>
                    :
                    <>
                        {/* Write a family story */}
                        <div className="fl-card mb-4" style={{position: "relative"}}>

                            {(memorialData.familyStory && (memorialData.role === "OWNER" || memorialData.role === "ADMIN")) &&
                                <img src="/images/fl-pen.svg" className="timeline-edit" width="32px" height="32px" />
                            }

                            <p className="timeline-obituary-title mb-2">{ t("Timeline.obituaryTitle", {ns: "components"}) }</p>

                            <p className="timeline-obituary-subtitle mb-3">
                                {memorialData.familyStory !== null ?
                                    memorialData.familyStory :
                                    t("Timeline.obituarySubTitle", {name: `${memorialData.lastName}`, ns: "components"})
                                }
                            </p>
                            
                            {memorialData.familyStory !== null ?
                                <p className="btn-txt" style={{float: "right"}}>{ t("Timeline.readObituary", {ns: "components"}) }</p> :
                                <ModalCreateFamilyStory memorialState={memorialData} />
                            }
                        </div>
                    </>
                }
            </div>

            {memoriesState?.length !== 0 ?
                <div className="timeline-memories-wrapper d-flex flex-column gap-3 py-3 mb-5">
                    <p className="ps-3">{ t("Timeline.memoriesTitle", {ns: "components"}) }</p>
                    <div className="timeline-memories d-flex gap-3 mb-3">
                        {memoriesState}
                    </div>
                </div> : ""
            }
            

            <div className="d-flex flex-column ps-5 pe-3">
                {lifeEventState}
            </div>

            <LifeEventDetailed title={selectedEventTitle} message={selectedEventMessage} eventDateDisplay={selectedEventDate} />
        </>
    );
}