import React, { useContext, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import { MemorialType, UpdateFamilyStoryType } from "../AppType";
import { Modal } from "react-bootstrap";
import { ASSET_URL, postFormData } from "../../utils/AppUtil";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import 'animate.css';
import { ModalSetLeafAmount } from "./ModalSetLeafAmount";

export const ModalDonateLeaf: React.FC = () => {
    
    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Refs
    const refs = useRef<(HTMLDivElement | null)[]>([]);

    // States
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [formStep, setFormStep] = useState<number>(0);
    const [show, setShow] = useState(false);
    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [modalSuccess, setModalSuccess] = useState<boolean>(false);
    const [modalFail, setModalFail] = useState<string>("");

    const [formState, setFormState] = useState<MemorialType>(memorialData);

    const [currency, setCurrency] = useState<string>('$');
    
    const [tipAmount, setTipAmount] = useState<number>(10);
    const [donateAmount, setDonateAmount] = useState<number>(0);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [scarletActive, setScarletActive] = useState<boolean>(false);

    const backgroundImg: string = `${ASSET_URL}/public/picture/${memorialData.coverPictureId}`
    let backgroundStyle;
    if (backgroundImg !== null) {
        backgroundStyle = {backgroundImage: `url(${backgroundImg})`};
    }

    // Array of leaf tiles data
    const leafTilesArray: any[] = [
        {
            type: 'Green Leaf',
            duration: 'Lasts 1 week',
            currency: currency,
            amount: 5,
            backgroundPosition: 'center top'
        },
        {
            type: 'Gold Leaf',
            duration: 'Lasts 6 weeks',
            currency: currency,
            amount: 20,
            backgroundPosition: 'center top'
        },
        {
            type: 'Platinum Leaf',
            duration: 'Lasts 1 year',
            currency: currency,
            amount: 50,
            backgroundPosition: 'center top'
        },
        {
            type: 'Scarlet Leaf',
            duration: 'Lasts forever',
            backgroundPosition: 'center top',
            amount: 0
        },
    ]

    const handleClick = (index: number) => {
        setActiveIndex(prevIndex => (prevIndex === index ? null : index));
        setDonateAmount(leafTilesArray[index].amount);

        if (index === 3) {
            setScarletActive(true);
        } else {
            setScarletActive(false);
        }
    }

    const handleCustomAmount = (newNumber: number) => {
        setDonateAmount(newNumber);
        setFormStep(formStep + 1);
    }

    const handleShow = () => {
        setModalSuccess(false);
        // setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideInUp');
        setShow(true);
    }

    const handleClose = () => {
        // setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideOutDown');
        setShow(false);
    }

    const handleReset = () => {
        setModalFail("");
    }

    // - - - - - Change Form Step - - - - -
    const handleClickNext = () => {
        if (formStep === 1) {
            setTotalAmount(donateAmount + tipAmount);
        }
        if (activeIndex !== null) {
            setFormStep(formStep + 1);
        }
    }

    const handleClickBack = () => {
        setFormStep(formStep - 1);
    }

    const handleTipInc = () => {
        if (tipAmount < 50) {
            setTipAmount(c => (c + 5));
        }
    }

    const handleTipDec = () => {
        if (tipAmount > 0) {
            setTipAmount(c => (c - 5));
        }
    }

    // Post data
    const handlePost = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        postFormData(
            commonData.token,
            `memorial/${memorialData.memorialId}`,
            formState,
            (response: any) => {
                setModalSuccess(true);
                console.log("handlePost Success Response:", JSON.stringify(response));

                setMemorialData((memorialData) => ({
                    ...memorialData,
                    familyStory: formState.familyStory
                }));
                
            },
            (error: any) => { // error
                setModalFail(JSON.stringify(error));
            }
        );
    }

    return (
        <>
            <button className="btn-light" onClick={handleShow} style={{border: "none"}}>
                <div className="btn-txt">{ t("TreeOfLife.donateALeaf", {ns: "components"}) }</div>
            </button>

            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header>
                    {formStep != 0 && <img src="/images/fl-arrow-back.svg" onClick={handleClickBack} className="form-progress-back"/>}
                    <button className="btn btn-modal-header" onClick={handleClose} />
                </Modal.Header>

                <Modal.Body>
                <div className="form-progress form-progress-absolute">
                        <img src={`/images/fl-progress-leaf.svg`} className={`form-progress-current form-progress-${formStep}`} alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" alt="" />
                    </div>

                    <div className="form-container">
                        <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep < 0 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="d-flex flex-column gap-2">

                                <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                    <p>Donate a Leaf</p>
                                    <p>By donating a leaf, you can help support one of Noam’s connected charities.</p>
                                </div>


                                <div className="donate-leaf-tiles px-3">
                                    {leafTilesArray.map((tile, index) => (
                                        <div
                                            key={index}
                                            ref={el => (refs.current[index] = el)}
                                            onClick={() => handleClick(index)}
                                            className={`donate-leaf-tile ${activeIndex === index ? 'leaf-active' : ''}`}
                                            style={{ backgroundPosition: activeIndex === index ? tile.backgroundPosition : 'center' }}
                                        >
                                            <div className={`leaf-tile-select ${activeIndex === index ? 'selected' : ''}`}>
                                                <img src="/images/check-tick.svg" alt="" />
                                            </div>

                                            <div className={`leaf-tile-text ${activeIndex === index ? 'selected' : ''}`}>
                                                <p>{tile.type}</p>
                                                <p>{tile.duration}</p>
                                                <p>{tile.currency}{tile.amount !== 0 ? tile.amount : ''}</p>
                                            </div>
                                            
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>

                        <div className={`form-screen form-content form-content-${formStep === 1 ? 'current' : (formStep < 1 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                <p>Please help us</p>
                                <p></p>
                            </div>

                            <div className="leaf-tip-wrapper">
                                <img src="/images/fl-logo-small.svg" width={40} height={40} alt="" />
                                <div className="leaf-tip-top">
                                    <p>Share the love</p>
                                    <p>Forever Loved doesn’t charge any fees on payments. Adding a small tip helps us to continue to help more people and charities.</p>
                                </div>

                                <hr />
                                
                                <div className="leaf-tip-bottom">
                                    <p>Tip to Forever Loved</p>
                                    <div className="leaf-tip-amount">
                                        <img src="/images/icon-circle-minus.svg" onClick={handleTipDec} width={24} height={24} alt="" />
                                        <p>{currency}{tipAmount}.00</p>
                                        <img src="/images/icon-circle-plus.svg" onClick={handleTipInc} width={24} height={24} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className={`form-screen form-content form-content-${formStep === 2 ? 'current' : (formStep < 2 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                        <div className="d-flex flex-column gap-2">
                            
                            <div className="modal-body-heading d-flex flex-column gap-2 mb-4">
                                <p>Select your charity</p>
                                <p>The following charities are connected to communities that Noam is part of.</p>
                            </div>

                            <div className="leaf-charities-wrapper px-3">
                                <div className="leaf-charity-tile">
                                    <img src="/images/charity-placeholder-cancer.png" />
                                    <p>Cancer Research</p>
                                </div>

                                <div className="leaf-charity-tile">
                                    <img src="/images/charity-placeholder-agency.png" />
                                    <p>The Jewish Agency for Israel</p>
                                </div>

                                <div className="leaf-charity-tile">
                                    <img src="/images/charity-placeholder-cancer.png" />
                                    <p>Cancer Research</p>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div className={`form-screen form-content form-content-${formStep === 3 ? 'current' : (formStep < 3 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="modal-body-heading d-flex flex-column gap-1 mb-4">
                                <p className="mb-3">Your donation</p>
                                <p>Total payment</p>
                                <span className="donate-total">{currency}{totalAmount}.00</span>
                            </div>

                            <div className="reoccuring-donation-switch form-check form-switch">
                                <input className="form-check-input m-0" type="checkbox" role="switch" id="toggleLanguageSwitch" placeholder="" title=""/>
                                <label className="form-check-label" htmlFor="toggleLanguageSwitch">Make my donation reoccurring (every 6 weeks).</label>
                            </div>

                            <div className="d-flex align-items-center gap-3">
                                <img src="/images/fl-heart.svg" width={16} height={16} />
                                <p className="input-subtext" style={{color: "#FFFFFF"}}>A leaf will be placed on Noam’s tree automatically every 6 weeks.</p>
                            </div>

                            <hr className="form-hr mx-auto" />

                            <div className="d-flex gap-2">
                                <div className="payment-gateway-btn">Card</div>
                                <div className="payment-gateway-btn">Apple Pay</div>
                                <div className="payment-gateway-btn">PayPal</div>
                            </div>

                        </div>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {formStep !== 3 &&
                        <>
                            {(formStep === 0 && scarletActive) ?
                                <ModalSetLeafAmount setCustomAmount={handleCustomAmount} />
                                :
                                <button className="btn btn-modal-footer" onClick={handleClickNext}>Continue</button>
                            }
                        </>
                    }

                    {formStep === 3 && <button className="btn btn-modal-footer" onClick={handleClickNext}>Pay Now</button> }
                    
                    <div className="modal-footer-links d-flex justify-content-center gap-3 pt-4 pb-2 m-0 w-100">
                        <p>Privacy Policy</p>
                        <div className="modal-link-separator" />
                        <p>Terms & Conditions</p>
                    </div>
                </Modal.Footer>

                {modalSuccess && 
                    <div className="modal-success-div animate__animated animate__slideInRight">
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center" style={{height: "85%"}}>
                            <img src="/images/fl-login-tick.svg" />
                            <div className="login-success-txt d-flex flex-column gap-1">
                                <p>Family story added</p>
                                <p></p>
                            </div>
                        </div>

                        <button onClick={handleClose} className="btn fl-btn-modal-bottom">OK</button>
                    </div>
                }

                {modalFail !== "" && 
                    <div className="modal-success-div animate__animated animate__slideInRight">
                        <div className="d-flex flex-column gap-2 align-items-center justify-content-center" style={{height: "85%"}}>
                            <img src="/images/fl-login-tick.svg" />
                            <div className="login-success-txt d-flex flex-column gap-1">
                                <p>Failed :(</p>
                                <p>Error: {modalFail}</p>
                            </div>
                        </div>

                        <button onClick={handleReset} className="btn fl-btn-modal-bottom">Try Again</button>
                    </div>
                }
            </Modal>
        </>
    );
}





