import React, { useContext, useEffect, useRef, useState } from "react";
import AvatarEditor from 'react-avatar-editor';
import imageCompression from "browser-image-compression";
import { AppContext } from "../../AppContext";
import { MemoryCreateType, PictureGalleryType, PictureUploadType } from "../AppType";
import { Modal } from "react-bootstrap";
import { ASSET_URL, fetchImage, fetchRecords, postFormData, postFormPictureFormData } from "../../utils/AppUtil";
import { useSpring } from "@react-spring/web";
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react'
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import 'animate.css';

const useGesture = createUseGesture([dragAction, pinchAction]);


type ModalProps = {
    triggerMemory: (value: string) => void;
}

export const CreateMemory: React.FC<ModalProps> = ({triggerMemory}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    // Cropping & zooming refs
    const pinchRef = useRef<HTMLDivElement>(null!)
    const editorRef = useRef<AvatarEditor>(null!);
    const pictureInputRef = useRef<HTMLInputElement>(null!);

    // formState for loading image to gallery
    const initForm: PictureUploadType = {
        memorialId: memorialData.memorialId,
        file: null
    }
    const [formState, setFormState] = useState<PictureUploadType>(initForm);

    // formState for the memory being created
    const initFormMemory: MemoryCreateType = {
        title: "",
        message: "",
        pictureIds: [],
        extra: {
            additionalProp1: {},
            additionalProp2: {},
            additionalProp3: {}
        }
    }
    const [formStateMemory, setFormStateMemory] = useState<MemoryCreateType>(initFormMemory);

    // Form progression
    const [formStep, setFormStep] = useState<number>(0);

    // Gallary & Editor states
    const [formStateImages, setFormStateImages] = useState<string[]>([])
    const [newImage, setNewImage] = useState<File>();
    const [newImages, setNewImages] = useState<File[]>([]);
    const [zoom, setZoom] = useState<number>(1);
    const [addPhotos, setAddPhotos] = useState<boolean>(false);
    const [imageGallery, setImageGallery] = useState<React.ReactElement[]>();

    // Modal handlers
    const [animation, setAnimation] = useState<string>("animate__animated animate__flipInY");
    const [charactersRemaining, setCharactersRemaining] = useState<number>(240);
    const [charactersRemainingText, setCharactersRemainingText] = useState<string>(" characters max");
    const [btnNext, setBtnNext] = useState<boolean>(true);
    const [btnNextAnimation, setBtnNextAnimation] = useState<string>("");
    const [btnSkip, setBtnSkip] = useState<boolean>(false);
    const [btnSkipAnimation, setBtnSkipAnimation] = useState<string>("");
    const [spinnerLoading, setspinnerLoading] = useState<boolean>(false);
    const [formProgressHide, setFormProgressionHide] = useState<string>("")
    const [modalSuccess, setModalSuccess] = useState<boolean>(true);

    // Initial settings for touch-zoom functionality
    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: zoom,
        rotateZ: 0,
    }));

    // useEffect to prevent default event behaviour for touch actions on component mount
    useEffect(() => {
        const handler = (e: Event) => e.preventDefault();
        document.addEventListener('gesturestart', handler);
        document.addEventListener('gesturechange', handler);
        document.addEventListener('gestureend', handler);

        // return runs cleanup on unmount to remove event listeners
        return () => {
            document.removeEventListener('gesturestart', handler);
            document.removeEventListener('gesturechange', handler);
            document.removeEventListener('gestureend', handler);
        };
    }, []);

    // Fetch gallery data
    useEffect(() => {
        fetchRecords(commonData.token, `picture/memorial/${formState.memorialId}`, processData);
    }, [memorialData, newImages, formStateImages]);

    // Process gallery data
    const processData = (responseJson: PictureGalleryType[]) => {
        setImageGallery(buildImageGallery(responseJson));
    }

    // Build gallery JSX
    const buildImageGallery = (data: PictureGalleryType[]): React.ReactElement[] => {
        const result: React.ReactElement[] = [];
        let key: number = 0;
    
        data.forEach((picture: PictureGalleryType) => {
            const pictureSrc: string = `${ASSET_URL}/public/picture/${picture.pictureId}`;
            const isSelected = formStateImages.includes(picture.pictureId);
            const index = formStateImages.indexOf(picture.pictureId);
    
            result.unshift(
                <div key={key} style={{ position: 'relative' }}>
                    <img
                        src={pictureSrc}
                        onClick={() => addImageToEvent(picture.pictureId)}
                        className={isSelected ? 'selected' : ''}
                    />
                    {isSelected && (
                        <div className="number-indicator">
                            {index + 1}
                        </div>
                    )}
                </div>
            );
            key++;
        });
    
        return result;
    }

    // Build new-images gallery JSX
    const buildNewImagesGallery = (): React.ReactElement[] => {
        return newImages.map((file, index) => {
            const imageUrl = URL.createObjectURL(file);
            return <img key={`new-${index}`} src={imageUrl} onClick={() => selectImageFromFile(file)} style={{ border: "1px solid yellow" }} />;
        });
    }

    // Select image from 'local' gallery
    const selectImageFromFile = (file: File) => {
        setNewImage(file);
    }

    // Process new images added to 'local' gallery
    const processNewFiles = (files: File[]) => {
        setNewImages((prevNewImages) => [...prevNewImages, ...files]);
    }

    // Add selected image to the memory
    const addImageToEvent = (pictureId: string) => {
        setFormStateImages((prevFormStateImages) => {
            const updatedFormStateImages = prevFormStateImages.includes(pictureId)
                                           ? prevFormStateImages.filter(id => id !== pictureId)
                                           : [...prevFormStateImages, pictureId];
            return updatedFormStateImages;
        });
    }

    // Build image array used for the memory
    const buildImageArray = (): React.ReactElement[] => {
        return formStateImages.map((id, index) => {
            const imageUrl: string = `${ASSET_URL}/public/picture/${id}`;
            return <img key={`new-${index}`} src={imageUrl} style={{borderRadius: "8px", marginRight: "12px"}} width={124} height={124} />;
        });
    }

    // - - - - - Scroll Zoom - - - - -
    const scaleStep = 0.1;
    document.getElementById('selectedImageContainer')?.addEventListener('wheel', (event) => {
        event.preventDefault();
        
        if (event.deltaY < 0) {
            setZoom(zoom + scaleStep);
        } else {
            setZoom(zoom - scaleStep);
        };
    });

    const deactivateZoomDiv = document.getElementById('deactivateZoom');
    deactivateZoomDiv?.addEventListener('touchmove', function(event) {
        const touchEvent = event as TouchEvent & { scale: number };
        if (touchEvent.scale !== 1) {
            event.preventDefault();
        }
    }, {passive: false});

    useGesture(
        {
            onDrag: ({pinching, cancel, offset: [x, y], ...rest}) => {
                if (pinching) return cancel();
                api.start({ x, y });
            },
            onPinch: ({offset: [s], first}) => {
                if (first) {
                    const {width, height, x, y} = pinchRef.current!.getBoundingClientRect();
                    const tx = x + width / 2;
                    const ty = y + height / 2;
                    api.start({ x: -tx, y: -ty });
                }
                setZoom(s);
            },
        },
        {
            target: pinchRef,
            drag: {from: () => [style.x.get(), style.y.get()]},
            pinch: {scaleBounds: {min: 1, max: 5}, rubberband: false},
        }
    );

    // Form change (non date fields)
    const handleFormChange = (e: React.ChangeEvent<any>) => {

        if (e.target.type === "text") {
            setFormStateMemory(() => ({
                ...formStateMemory,
                [e.target.id]: e.target.value
            }));
        }

        if (e.target.id === "message") {

            const newCharactersRemaining = 240 - e.target.value.length;
            setCharactersRemaining(newCharactersRemaining);

            if (newCharactersRemaining < 240) {
                setCharactersRemainingText(" characters remaining");
            } else {
                setCharactersRemainingText(" characters max");
            }

            setFormStateMemory(() => ({
                ...formStateMemory,
                [e.target.id]: e.target.value
            }));
        }

        if (e.target.type === "file" && e.target.files != null) {
            const filesArray: File[] = Array.from(e.target.files);
            processNewFiles(filesArray);
        }
    }



    const handleClickNext = () => {
        if (formStep === 1) {
            setBtnNextAnimation('animate__animated animate__fadeOutDown animate__faster');
            setTimeout(() => {
                setBtnNext(false);
                setBtnSkip(true);
                setBtnSkipAnimation('animate__animated animate__fadeInUp animate__faster');
            }, 500);
        }
        setFormStep(formStep + 1);
    }

    // Navigate form back
    const handleClickBack = () => {
        if (formStep === 2) {
            setBtnSkipAnimation('animate__animated animate__fadeOutDown animate__faster');
            setTimeout(() => {
                setBtnSkip(false);
                setBtnNext(true);
                setBtnNextAnimation('animate__animated animate__fadeInUp animate__faster');
            }, 500);
        }

        if (formStep === 3) {
            setBtnSkipAnimation('animate__animated animate__fadeInUp animate__faster');
            setBtnSkip(true);
            setFormProgressionHide("");
        }

        setFormStep(formStep - 1);
    }

    const handleClickBackClose = () => {
        setAnimation("animate__animated animate__flipOutY");
        triggerMemory("animate__animated animate__flipInY");
    }

    // Add Images
    const handleAddPhotos = () => {
        setAddPhotos(true);
        setFormProgressionHide("animate__animated animate__fadeOutLeft animate__faster");
        setFormStep(formStep + 1);

        setBtnSkipAnimation('animate__animated animate__fadeOutDown animate__faster');
        setTimeout(() => {
            setBtnSkip(false);
        }, 500);
    }
    const handleAddImageArray = () => {
        setFormStateMemory((prevState) => ({
            ...prevState,
            pictureIds: formStateImages
        }));

        setFormStep(formStep + 1);
    }

    const handleScanPhotos = () => {

    }

    const handleAddVideos = () => {
        
    }



    // Cropping
    const handleCrop = async (): Promise<Blob> => {
        const canvasImage: HTMLCanvasElement = editorRef.current.getImage();
    
        return new Promise((resolve, reject) => {
            canvasImage.toBlob((blob: any) => {
                if (!blob) {
                    reject(new Error("Failed to convert canvas to Blob"));
                    return;
                }
    
                let name = "";
                if (pictureInputRef?.current?.files?.length) {
                    name = pictureInputRef.current.files[0].name;
                }
    
                setFormState((formState): PictureUploadType => {
                    const form = { ...formState, file: blob };
                    return form;
                });
    
                resolve(blob);
            });
        });
    }

    const handleCompression = async (file: any): Promise<Blob> => {
        console.log('originalFile instanceof Blob', file instanceof Blob); // true
        console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
    
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };
    
        try {
            const compressedFile = await imageCompression(file, options);
            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    
            return compressedFile;
        } catch (error: any) {
            console.log(error.message);
            throw error;
        }
    }

    // Post data
    const handlePostPicture = async (compressedFile: Blob, fileName: string) => {
        console.log('Posting Form State:', formState);
        console.log('Posting File:', compressedFile);
    
        const formData = new FormData();
        formData.set("memorialId", formState.memorialId);
        formData.set("file", compressedFile);
    
        postFormPictureFormData(
            commonData.token,
            'picture',
            formData,
            (responseData: any) => {
                console.log("Multi-post Success", responseData);
                setNewImages((prevNewImages) => prevNewImages.filter((file) => file.name !== fileName));
            },
            (response: any) => { // error
                console.log("Multi-post Fail", response);
            }
        );
    }

    const handleClick = async (): Promise<void> => {
        try {
            setspinnerLoading(true);
            const croppedBlob = await handleCrop();
            console.log('Cropping completed successfully.');
            console.log('CROPPED:', croppedBlob);
    
            const compressedBlob = await handleCompression(croppedBlob);
            console.log('Compression completed successfully.');
            console.log('COMPRESSED:', compressedBlob);
    
            // Directly call handlePost and pass the compressed file and its name
            await handlePostPicture(compressedBlob, newImage!.name);
    
            setspinnerLoading(false);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }


    const handleSave = () => {
        console.log(formStateMemory);
    }


    return (
        <>
            <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep < 0 ? 'prev' : 'next')} h-100 ${animation}`} style={{backgroundColor: "#00222F", zIndex: "999"}}>

                <Modal.Header>
                    {formStep === 0 ?
                        <img src="/images/fl-arrow-back.svg" onClick={handleClickBackClose} className="form-progress-back"/>
                        :
                        <img src="/images/fl-arrow-back.svg" onClick={handleClickBack} className="form-progress-back"/>
                    }
                    <p className="modal-title">New Memory</p>
                    <button className="btn btn-modal-header" onClick={handleClickBackClose} />
                </Modal.Header>
                

                <Modal.Body>
                    <div className={`form-progress form-progress-absolute ${formProgressHide}`}>
                        <img src={`/images/fl-progress-camera.svg`} className={`form-progress-current form-progress-${formStep}`} />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3" />
                    </div>

                    <div className="form-container">

                        <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep < 0 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <p className="modal-heading mb-4">What’s this memory called?</p>

                            
                            <div className="login-form">
                                <div className="mb-4">
                                    <label htmlFor="title" className="form-label">Title</label>
                                    <input id="title" name="title" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter a short title for this event" />
                                </div>
                            </div>
                        </div>

                        <div className={`form-screen form-content form-content-${formStep === 1 ? 'current' : (formStep < 1 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="form-floating">
                                <textarea id="message" onChange={handleFormChange} maxLength={240} className="form-control memory-message-input pt-0" placeholder="Write a description of the memory" />
                                <p className="memory-message-limit">{charactersRemaining} {charactersRemainingText}</p>
                            </div>
                        </div>

                        <div className={`form-screen form-content form-content-${formStep === 2 ? 'current' : (formStep < 2 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <p className="modal-heading mb-4">Upload some photos, videos & audio.</p>

                            <div className="d-flex flex-column">
                                <div className="image-upload-menu-item" onClick={handleAddPhotos}>
                                    <div className="d-flex gap-3">
                                        <img src="images/camera-icon.svg" width={24} />
                                        <p className="event-add-assets">Add photos</p>
                                    </div>
                                </div>

                                <div className="image-upload-menu-item" onClick={handleScanPhotos}>
                                    <div className="d-flex gap-3">
                                        <img src="images/scan-icon.svg" width={24} />
                                        <p className="event-add-assets">Scan a photo</p>
                                    </div>
                                </div>

                                <div className="image-upload-menu-item" onClick={handleAddVideos}>
                                    <div className="d-flex gap-3">
                                        <img src="images/recorder-icon.svg" width={24} />
                                        <p className="event-add-assets">Add Videos</p>
                                    </div>
                                    <div className="premium-badge">Premium</div>
                                </div>
                                
                            </div>
                        </div>

                        <div className={`event-photos form-screen form-content form-content-${formStep === 3 ? 'current' : (formStep < 3 ? 'prev' : 'next')}`}>


                            {addPhotos === true &&
                                <>
                                    <div className="event-photo-uploading">
                                        <div ref={pinchRef} id="deactivateZoom" style={{height: "100vw"}}>
                                            <div className="d-flex justify-content-center">
                                                <div className="image-canvas-grid" />

                                                {spinnerLoading &&
                                                    <div className="image-canvas-loading animate__animated animate__fadeIn animate__faster">
                                                        <img src="images/creating-memorial.svg" />
                                                        <p className="loading-ellipsis">Uploading Image</p>
                                                    </div>
                                                }

                                                <div className="memorial-img-crop-wrapper d-flex justify-content-center align-items-center">
                                                    <div id="selectedImageContainer" className="memorial-img-crop-preview">
                                                        {newImage &&
                                                            <AvatarEditor
                                                                ref={editorRef}
                                                                image={newImage}
                                                                border={0}
                                                                borderRadius={0}
                                                                scale={zoom}
                                                                rotate={0}
                                                                style={{
                                                                    transform: `translate(${style.x}px, ${style.y}px) scale(${style.scale}) rotate(${style.rotateZ}deg)`,
                                                                    touchAction: "pinch-zoom",
                                                                    width: "100vw",
                                                                    height: "100vw"
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="image-gallery-wrapper d-flex flex-column w-100" style={{position: "relative"}} >
                                            <div className="image-upload-wrapper justify-content-evenly d-flex p-3">
                                                <label className="btn btn-image-upload">
                                                    <input ref={pictureInputRef} id="file" name="file" type="file" accept="image/*" multiple onChange={handleFormChange} />
                                                    <img src="/images/fl-plus-lg.svg" style={{marginBottom: "2px"}} /> Upload photos
                                                </label>
                                                <button className="btn btn-image-upload" onClick={handleClick}>Add to gallery</button>
                                            </div>
                                            
                                            <div className="image-gallery d-flex flex-row flex-wrap">
                                                {/* <div className={imageGalleryLoading}><img src="/images/fl-logo-small.svg" className={imageGalleryLoadingLogo} /></div> */}
                                                {buildNewImagesGallery()}
                                                {imageGallery}
                                            </div>
                                            
                                            
                                            {formStateImages && formStateImages.length !== 0 && <div className="image-gallery-fade mb-4 animate__animated animate__slideInUp animate__faster"></div>}
                                            {formStateImages && formStateImages.length !== 0 && <button className="btn fl-btn-modal-bottom mb-4 animate__animated animate__slideInUp animate__faster" onClick={handleAddImageArray} type="button">Add</button>}
                                        </div>


                                    </div>
                                </>
                            }
                        </div>

                        <div className={`form-screen form-content form-content-${formStep === 4 ? 'current' : (formStep < 4 ? 'prev' : 'next')}`} style={{height: "calc(100vh - 120px)"}}>
                            <p className="modal-heading mb-4">{formStateMemory.title}</p>

                            <div className="d-flex g-3 mb-4">
                                {buildImageArray()}
                            </div>

                            <p className="memory-preview-message">{formStateMemory.message}</p>
                            <button className={`btn btn-modal-footer`} onClick={handleClickNext} style={{position: "absolute", bottom: 0}}>Save Memory</button>
                        </div>

                        <div className={`form-screen form-content form-content-${formStep === 5 ? 'current' : (formStep < 5 ? 'prev' : 'next')}`}>

                            <div className="modal-success-div">
                                <div className="d-flex flex-column gap-2 align-items-center justify-content-center" style={{height: "85%"}}>
                                    <img src="/images/fl-login-tick.svg" />
                                    <div className="login-success-txt d-flex flex-column gap-1">
                                        <p>Memory Added</p>
                                        <p></p>
                                    </div>
                                </div>

                                <button className="btn fl-btn-modal-bottom">OK</button>
                            </div>
                            
                        </div>

                    </div>

                    
                </Modal.Body>

                {btnNext &&
                    <Modal.Footer>
                        <button className={`btn btn-modal-footer ${btnNextAnimation}`} onClick={handleClickNext}>Next</button>
                    </Modal.Footer>
                }
                {btnSkip &&
                    <Modal.Footer>
                        <button className={`btn-modal-footer-skip mx-auto ${btnSkipAnimation}`}>Skip</button>
                    </Modal.Footer>
                }

            </div>
        </>
    );
}
