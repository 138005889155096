import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";

export const AddToHome: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [show, setShow] = useState(false);

    const navigate = useNavigate();
    
    const handleClickLogout = () => {
        navigate('/login');
    }

    const handleShow = () => {
        setModalAnimation('modal-add-to-home');
        setShow(true);
    }

    const handleClose = () => {
        setModalAnimation('modal-add-to-home animate__animated animate__slideOutUp');
        setTimeout(() => {
            setShow(false);
        }, 300);
    }

    return (
        <>
            <img src="/images/fl-bell.svg" className="home-icon-top p-2" height={40} onClick={handleShow} style={{cursor: "pointer"}} />

            <Modal className={modalAnimation} show={show} onHide={handleClose}>
                <img src="/images/popup-caret.svg" style={{width: "34px", margin: "0 16px -1px auto"}} />
                <Modal.Body>
                    <div className="d-flex justify-content-between">
                        <p className="add-to-home-heading">Add to Home Screen</p>
                        <p className="add-to-home-copy" style={{color: "#0A84FF", cursor: "pointer"}} onClick={handleClose}>Cancel</p>
                    </div>

                    <hr style={{borderTopColor: "#00000080"}} />
                    <p className="add-to-home-copy">This website has app functionality. Add it to your home screen to improve your experience.</p>
                    <hr style={{borderTopColor: "#00000080"}} />

                    <div className="d-flex align-items-center gap-2 mb-2">
                        <img src="images/add-step-1.svg" />
                        <img src="images/add-step-1-icon.svg" />
                        <p className="add-to-home-copy">Press <b>‘Share’</b> on the menu bar below</p>
                    </div>

                    <div className="d-flex align-items-center gap-2">
                        <img src="images/add-step-2.svg" />
                        <img src="images/add-step-2-icon.svg" />
                        <p className="add-to-home-copy">Press <b>‘Add to Home Screen’</b></p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}