import React, { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import { Modal } from "react-bootstrap";
import { CreateLifeEvent } from "./CreateLifeEvent";
import { CreateMemory } from "./CreateMemory";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import 'animate.css';


export const ModalCreateEvent: React.FC = () => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;
    
    const [modalAnimation, setModalAnimation] = useState<string>('')
    const [cardOneAnimation, setCardOneAnimation] = useState<string>('')
    const [cardTwoAnimation, setCardTwoAnimation] = useState<string>('')
    const [show, setShow] = useState(false);

    const [eventSelected, setEventSelected] = useState<number>(0);
    const [selectLifeEvent, setSelectLifeEvent] = useState<boolean>(false);
    const [selectMemory, setSelectMemory] = useState<boolean>(false);
    const selectedBorder: string = "2px solid white";



    const handleShow = () => {
        // setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideInUp');
        setShow(true);
    }

    const handleClose = () => {
        // setModalAnimation('modal-account-drawer modal-account-drawer-background animate__animated animate__slideOutDown');
        // setTimeout(() => {
            setShow(false);
        // }, 300);
    }

    const handleLifeEventClick = () => {
        setSelectLifeEvent(true);
        setSelectMemory(false);
    }

    const handleMemoryClick = () => {
        setSelectMemory(true);
        setSelectLifeEvent(false);
    }

    const handleEventSelected = () => {
        if (selectLifeEvent) {
            setCardOneAnimation('animate__animated animate__flipOutY');
            setTimeout(() => {
                setEventSelected(1);
            }, 500);
        } else if (selectMemory) {
            setCardTwoAnimation('animate__animated animate__flipOutY');
            setTimeout(() => {
                setEventSelected(2);
            }, 500);
        }
    }

    const triggerLifeEvent = (value: string) => {
        setTimeout(() => {
            setCardOneAnimation(value);
            setEventSelected(0);
        }, 500);
    };

    const triggerMemory = (value: string) => {
        setTimeout(() => {
            setCardTwoAnimation(value);
            setEventSelected(0);
        }, 500);
    };

    const handleChangeEvent = () => {
        setSelectMemory(true);
        setSelectLifeEvent(false);

        setTimeout(() => {
            setCardTwoAnimation('animate__animated animate__flipOutY');
            setTimeout(() => {
                setEventSelected(2);
            }, 500);
        }, 1000);
    }

    return (
        <>

            <div className="timeline-btn mb-4 mt-2" onClick={handleShow} style={{cursor: "pointer"}}>
                <img src="/images/fl-plus-lg.svg" />
                <p className="timeline-btn-title">{ t("Timeline.addLifeEvent", {name: `${memorialData.firstName}`, ns: "components"}) }</p>
            </div>

            <Modal className={modalAnimation} show={show} fullscreen={true} onHide={handleClose}>
                <Modal.Header>
                    <p className="modal-title">New Event</p>
                    <button className="btn btn-modal-header" onClick={handleClose} />
                </Modal.Header>
                
                <Modal.Body>
                    <div className="d-flex flex-column gap-4">
                        <p className="modal-heading">Let’s create a new event</p>

                        <div className={`add-event-widget ${cardOneAnimation}`} onClick={handleLifeEventClick} style={{border: selectLifeEvent ? selectedBorder : ""}}>
                            <img src="/images/add-life-event.svg" width={23} height={28} />
                            <p className="title">Life Event</p>
                            <p className="subtitle">These events are for significant moments in {memorialData.firstName}’s life. They require a date.</p>
                        </div>
                        
                        <div className={`add-event-widget ${cardTwoAnimation}`} onClick={handleMemoryClick} style={{border: selectMemory ? selectedBorder : ""}}>
                            <img src="/images/add-memory.svg" width={34} height={25} />
                            <p className="title">Memories</p>
                            <p className="subtitle">These events are for memories you have of {memorialData.firstName}. They do not require a date.</p>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button className="btn btn-modal-footer" onClick={handleEventSelected}>Next</button>
                </Modal.Footer>

                {eventSelected === 1 && <CreateLifeEvent triggerLifeEvent={triggerLifeEvent} changeEvent={handleChangeEvent} />}
                {eventSelected === 2 && <CreateMemory triggerMemory={triggerMemory} />}
            </Modal>
        </>
    );
}





