import React, { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import { LifeEventCreateType, MemorialType } from "../AppType";
import { Modal } from "react-bootstrap";
import { postFormData } from "../../utils/AppUtil";
import { ModalRememberDate } from "./ModalRememberDate";
import { useTranslation } from "react-i18next";
import { MemorialContext } from "../../MemorialContext";
import 'animate.css';

interface ModalProps {
    triggerLifeEvent: (value: string) => void;
    changeEvent: () => void;
}

export const CreateLifeEvent: React.FC<ModalProps> = ({triggerLifeEvent, changeEvent}) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;

    const formData: LifeEventCreateType = {
        title: "",
        message: "",
        eventDate: "",
        eventDateDay: "",
        eventDateMonth: "",
        eventDateYear: ""
    }
    const [formState, setFormState] = useState<LifeEventCreateType>(formData);

    const [formStep, setFormStep] = useState<number>(0);
    const [formStepDate, setFormStepDate] = useState<boolean>(false);
    const [animation, setAnimation] = useState<string>("animate__animated animate__flipInY");

    const [invalidDate, setInvalidDate] = useState<boolean>(false);
    const [invalidDay, setInvalidDay] = useState<boolean>(false);
    const [invalidMonth, setInvalidMonth] = useState<boolean>(false);
    const [invalidYear, setInvalidYear] = useState<boolean>(false);
    const [modalSuccess, setModalSuccess] = useState<boolean>(false);

    const invalidBorder: string = '3px solid #DC3545';

    const handleChildClick = () => {
        handleClickBackClose();
        changeEvent();
    };

    // Validation (day)
    const validateDay = (value: string): string => {
        let numberValue: number = parseInt(value, 10);

        if (isNaN(numberValue) || /\D/.test(value)) {
            setInvalidYear(true);
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            setInvalidDay(false);
            return '0' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            setInvalidDay(false);
            return numberValue.toString();

        } else {
            setInvalidDay(true);
            return 'invalid';
        }
    }

    // Validation (month)
    const validateMonth = (value: string): string => {
        let numberValue: number = parseInt(value, 10);
    
        if (isNaN(numberValue) || /\D/.test(value)) {
            setInvalidYear(true);
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            setInvalidMonth(false);
            return '0' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            setInvalidMonth(false);
            return numberValue.toString();

        } else {
            setInvalidMonth(true);
            return 'invalid';
        }
    }

    // Validation (year)
    const validateYear = (value: string): string => {

        let numberValue: number = parseInt(value, 10);

        if (isNaN(numberValue) || /\D/.test(value)) {
            setInvalidYear(true);
            return 'invalid';

        } else if (numberValue >= 0 && numberValue < 10) {
            setInvalidYear(false);
            return '000' + numberValue.toString();

        } else if (numberValue >= 10 && numberValue < 100) {
            setInvalidYear(false);
            return '00' + numberValue.toString();

        } else if (numberValue >= 100 && numberValue < 1000) {
            setInvalidYear(false);
            return '0' + numberValue.toString();

        } else if (numberValue >= 1000 && numberValue < 10000) {
            setInvalidYear(false);
            return numberValue.toString();

        } else {
            setInvalidYear(true);
            return 'invalid';
        }
    }

    // Form change (non date fields)
    const handleFormChange = (e: React.ChangeEvent<any>) => {

        if (e.target.type === "text") {
            setFormState((formState) => ({
                ...formState, 
                [e.target.id]: e.target.value
            }));
        }

        if (e.target.type === "select-one") {
            setFormState((formState) => ({
                ...formState, 
                [e.target.id]: e.target.value
            }));
        }
    }

    // Form change (date fields)
    const handleFormChangeDate = (e: React.ChangeEvent<any>) => {

        if (e.target.type === "text") {

            // - - - - - Death Date - - - - -
            if (e.target.id === "eventDateDay") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateDay(e.target.value)
                }));
            }
            if (e.target.id === "eventDateMonth") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateMonth(e.target.value)
                }));
            }
            if (e.target.id === "eventDateYear") {
                setFormState((formState) => ({
                    ...formState, 
                    [e.target.id]: validateYear(e.target.value)
                }));
            }
        }
    }

    const handleClickNext = () => {
        if (formStep === 1) {
            if (!formStepDate) {
                setFormStepDate(true);
            } else {
                setFormStep(formStep + 1);
            }
        } else {
            setFormStep(formStep + 1);
        }
    }

    // Navigate form back
    const handleClickBack = () => {
        setFormStep(formStep - 1);
    }

    const handleClickBackClose = () => {
        setAnimation("animate__animated animate__flipOutY");
        triggerLifeEvent("animate__animated animate__flipInY");
    }

    // Close modal after completion
    // const handleModalReset = () => {
    //     setModalSuccess(false);
    // }

    // Post data
    const handlePost = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();

        postFormData(
            commonData.token,
            '',
            formState,
            (response: any) => {
                setFormState(formData);
            },
            (error: any) => { // error
            }
        );

        setModalSuccess(true);
    }

    // const handleAddPhotos = () => {
    //     setAddPhotosStyle("full-screen-div animate__animated animate__slideInRight");
    //     setAddPhotos(true);
    //     setFormProgressionHide("animate__animated animate__fadeOutLeft animate__faster");
    //     setFormStep(formStep + 1);

    //     setBtnSkipAnimation('animate__animated animate__fadeOutDown animate__faster');
    //     setTimeout(() => {
    //         setBtnSkip(false);
    //     }, 500);

    //     setTimeout(() => {
    //         setFormProgressionHide("");
    //     }, 1000);
    // }

    // const handleScanPhotos = () => {

    // }

    // const handleAddVideos = () => {
        
    // }


    return (
        <>
            <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep < 0 ? 'prev' : 'next')} h-100 ${animation}`} style={{backgroundColor: "#00222F", zIndex: "999"}}>
                
                <Modal.Header>
                    {formStep === 0 ?
                        <img src="/images/fl-arrow-back.svg" onClick={handleClickBackClose} className="form-progress-back"/>
                        :
                        <img src="/images/fl-arrow-back.svg" onClick={handleClickBack} className="form-progress-back"/>
                    }
                    <p className="modal-title">New Life Event</p>
                    <button className="btn btn-modal-header" onClick={handleClickBackClose} />
                </Modal.Header>

                <Modal.Body>
                    <div className={`form-progress form-progress-absolute`}>
                        <img src={`/images/fl-progress-book.svg`} className={`form-progress-current form-progress-${formStep}`} />
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                        <img src={`/images/fl-progress.svg`} width={8} height={8} className="mt-3"/>
                    </div>

                    <div className="form-container">

                        <div className={`form-screen form-content form-content-${formStep === 0 ? 'current' : (formStep < 0 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="d-flex flex-column gap-2 mb-4">
                                <p className="modal-heading">What’s this life event called?</p>
                            </div>
                            
                            <div className="login-form">
                                <div className="mb-4">
                                    <label htmlFor="title" className="form-label">Title</label>
                                    <input id="title" name="title" type="text" onChange={handleFormChange} className="form-control" placeholder="Enter a short title for this event" />
                                </div>
                            </div>
                        </div>

                        <div className={`form-screen form-content form-content-${formStep === 1 ? 'current' : (formStep < 1 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                            <div className="d-flex flex-column gap-2 mb-4">
                                <p className="modal-heading">When did <i>“{formState.title}”</i> happen?</p>
                            </div>
                            
                            <div className="login-form">
                                <div className="d-flex gap-3 mb-4">
                                    
                                    <div className="row w-100">
                                        <div className="form-group col-4">
                                            <label htmlFor="eventDateYear" className="form-label">Year*</label>
                                            <input id="eventDateYear" name="eventDateYear" type="text" onChange={handleFormChangeDate} className="form-control" placeholder="YYYY" style={{border: invalidYear ? invalidBorder : ""}} />
                                        </div>
                                        
                                        {formStepDate && 
                                            <>
                                                <div className="form-group col-4 animate__animated animate__fadeInRight">
                                                    <label htmlFor="eventDateMonth" className="form-label">Month</label>
                                                    <input id="eventDateMonth" name="eventDateMonth" type="text" onChange={handleFormChangeDate} className="form-control" placeholder="MM" style={{border: invalidMonth ? invalidBorder : ""}} />
                                                </div>

                                                <div className="form-group col-4 animate__animated animate__fadeInRight">
                                                    <label htmlFor="eventDateDay" className="form-label">Day</label>
                                                    <input id="eventDateDay" name="eventDateDay" type="text" onChange={handleFormChangeDate} className="form-control" placeholder="DD" style={{border: invalidDay ? invalidBorder : ""}} />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className={`form-screen form-content form-content-${formStep === 2 ? 'current' : (formStep < 2 ? 'prev' : 'next')}`} style={{paddingTop: "80px"}}>
                        <div className="d-flex flex-column gap-2 mb-4">
                                <p className="modal-heading">Upload some photos, videos & audio.</p>
                            </div>

                            <div className="d-flex flex-column">
                                <div className="image-upload-menu-item">
                                    <div className="d-flex gap-3">
                                        <img src="images/camera-icon.svg" width={24} />
                                        <p className="event-add-assets">Add photos</p>
                                    </div>
                                </div>

                                <div className="image-upload-menu-item">
                                    <div className="d-flex gap-3">
                                        <img src="images/scan-icon.svg" width={24} />
                                        <p className="event-add-assets">Scan a photo</p>
                                    </div>
                                </div>

                                <div className="image-upload-menu-item">
                                    <div className="d-flex gap-3">
                                        <img src="images/recorder-icon.svg" width={24} />
                                        <p className="event-add-assets">Add Videos</p>
                                    </div>
                                    <div className="premium-badge">Premium</div>
                                </div>

                                <div className="image-upload-menu-item" style={{border: "none"}}>
                                    <div className="d-flex gap-3">
                                        <img src="images/fl-music.svg" width={24} />
                                        <p className="event-add-assets">Add Audio</p>
                                    </div>
                                    <div className="premium-badge">Premium</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </Modal.Body>

                <Modal.Footer className="justify-content-center">
                    <button className={`btn btn-modal-footer `} onClick={handleClickNext}>Next</button>
                    {formStep === 1 && <ModalRememberDate onButtonClick={handleChildClick} />}
                    
                </Modal.Footer>
            </div>
        </>
    );
}





