import React, { useContext } from "react";
import { MemorialContext } from "../../MemorialContext";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../AppContext";

type LifeEventProps = {
    title: string,
    message: string,
    eventDateDisplay: string,
}

export const LifeEventDetailed: React.FC<LifeEventProps> = (props) => {

    // Internationalisation
    const { t } = useTranslation();

    // Authentication data from AppContext
    const { commonData, setCommonData } = useContext(AppContext)!;

    // Memorial data from MemorialContext
    const { memorialData, setMemorialData } = useContext(MemorialContext)!;
    
    return (
        <div className="modal-life-event modal fade" id="lifeEventDetailed" tabIndex={999} aria-labelledby="lifeEventDetailed" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen" style={{zIndex: "999", backgroundColor: "#00222F"}}>
                <div className="modal-content" style={{backgroundColor: "#00222F"}}>
                    <div className="modal-body" style={{scrollbarWidth: "none", backgroundColor: "#00222F"}}>
                        <div className="d-flex justify-content-between mb-5">
                            <img src="/images/fl-arrow-back.svg" data-bs-dismiss="modal" aria-label="Close" width="21px" style={{cursor: "pointer"}}/>
                            <div className="fl-btn-share" style={{width: "auto", cursor: "pointer"}}>
                                <img src="/images/fl-share.svg" />
                                <p>Share</p>
                            </div>
                        </div>
                    
                        <div className="d-flex flex-column gap-2">
                            <div className="timeline-location-pill d-flex gap-2 px-2 py-1 mb-4">
                                <img src="/images/fl-map-pin.svg" />
                                <p>Cape Town, SA</p>
                            </div>
                            <p className="fl-txt-title">{props.title}</p>
                            <p className="fl-life-event-date">{props.eventDateDisplay}</p>
                            <p className="fl-txt-subtitle">{props.message}</p>
                        </div>

                        <div className="life-event-detailed-image-wrapper d-flex flex-column align-items-center gap-5 mt-5">
                            <img src="/images/carouselPlaceholders/carousel-img-1.png" className="life-event-detailed-image" />
                            <img src="/images/carouselPlaceholders/carousel-img-2.png" className="life-event-detailed-image" />
                            <img src="/images/carouselPlaceholders/carousel-img-3.png" className="life-event-detailed-image" />
                            <img src="/images/carouselPlaceholders/carousel-img-4.png" className="life-event-detailed-image" />
                        </div>

                    </div>
                
                </div>
            </div>
        </div>
    );
}